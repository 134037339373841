<template>
    <v-card>
        <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <!-- Edited users-->
        <v-card-text>
            <v-form v-model="isValid">
                <v-container class="py-0">
                    <v-row>
                        <v-col
                            v-if="editedItem.id"
                            cols="12"
                            md="3"
                            lg="2"
                            sm="4"
                        >
                            <v-text-field
                                v-model="editedItem.id"
                                class="purple-input"
                                :label="$t('admin.games.id')"
                                :disabled="true"
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            md="3"
                            lg="2"
                            sm="4"
                        >
                            <v-select
                                v-model="editedItem.game_type_id.value"
                                :error-messages="editedItem.game_type_id.error"
                                :items="gameTypes"
                                attach
                                :label="$t('admin.games.type')"
                                item-text="name"
                                item-value="id"
                                :rules="[rules.required]"
                                :disabled="isOnlyView || !!item"
                                @change="editedItem.game_type_id.error = ''"
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            md="3"
                            lg="2"
                            sm="4"
                        >
                            <v-text-field
                                v-model="editedItem.teams_count.value"
                                class="purple-input"
                                :label="$t('admin.games.teamsCount')"
                                :error-messages="editedItem.teams_count.error"
                                :rules="[rules.required, rules.validNumber]"
                                :disabled="isOnlyView || !!item"
                                @keyup="editedItem.teams_count.error = ''"
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            md="3"
                            lg="2"
                            sm="4"
                        >
                            <v-text-field
                                v-model="editedItem.name.value"
                                class="purple-input"
                                :label="$t('common.name')"
                                :error-messages="editedItem.name.error"
                                :disabled="isOnlyView"
                                @keyup="editedItem.name.error = ''"
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            md="12"
                            lg="4"
                            sm="8"
                        >
                            <v-text-field
                                v-model="editedItem.description.value"
                                class="purple-input"
                                :label="$t('common.description')"
                                :error-messages="editedItem.description.error"
                                :disabled="isOnlyView"
                                @keyup="editedItem.description.error = ''"
                            />
                        </v-col>
                    </v-row>
                </v-container>

                <v-divider class="mt-4 mb-2 mx-8 primary" />

                <v-col
                    v-if="hasCards"
                    cols="12"
                    sm="12"
                >
                    <!--                              :disabled="isOnlyView"-->

                    <v-select
                        v-model="editedItem.cardTypes.value"
                        :error-messages="editedItem.cardTypes.error"
                        :items="cardTypes"
                        attach
                        chips
                        :label="$t('admin.games.cardTypes')"
                        multiple
                        item-text="name"
                        item-value="id"
                        @change="changeCardTypesSelected"
                    />
                </v-col>
                <!--                <cards-game
                    v-if="editedItem && editedItem.id"
                    :game-id="editedItem.id"
                />-->

                <v-divider class="mt-4 mb-2 mx-8 primary" />
                <players-game
                    v-if="hasPlayers"
                    :game-id="editedItem.id"
                    :teams_count="editedItem.teams_count.value"
                />
            </v-form>
        </v-card-text>

        <v-card-actions class="flex-wrap justify-end">
            <v-btn
                v-if="!!item && gameTypeHelper.hasInfo(item)"
                color="blue darken-1"
                text
                @click="infoItem(item)"
            >
                {{ $t('common.infoTitle') }}
            </v-btn>

            <v-btn
                color="blue darken-1"
                text
                @click="$router.go(-1)"
            >
                {{ $t(`common.${ isOnlyView || item ? 'endProcess' : 'confirmDialogCancel'}`) }}
            </v-btn>
            <v-btn
                v-if="!isOnlyView"
                color="blue darken-1"
                text
                :disabled="!isValid && !serverCheck"
                @click="save"
            >
                {{ $t('common.dialogSave') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import axios from '@/plugins/axios'
import rules from '@/helpers/rulesValidate'

export default {
    components: {

        PlayersGame: () => import('@/components/admin/games/playersGameList')
    },
    data: function () {
        return {
            urlMain: 'admin/games/games',
            rules: {
                ...rules
            },
            gameId: null,
            item: null,
            editedItem: this.getEditedItem(),
            isValid: true,
            serverCheck: false,
            isOnlyView: false,
            gameTypes: [],
            cardTypes: []
        }
    },
    computed: {
        formTitle() {
            if (this.item) { return this.isOnlyView ? this.$t('admin.games.view') : this.$t('admin.games.edit') } else return this.$t('admin.games.new')
        },
        hasCards() {
            if (!this.item?.game_type_id) return false;

            return this.gameTypeHelper.hasCards(this.item);
        },
        hasPlayers() {
            if (!this.editedItem?.id) return false; // Firstly is necessary create the game

            return this.gameTypeHelper.hasPlayers(this.item);
        }
        // ...mapGetters(['role', 'cities'])
    },
    async created() {
        // const itemParam = this.$route.params.item;

        this.gameId = this.$route.params.id;

        /* if (itemParam) {
            this.item = itemParam;
        } */

        if (Number.isInteger(+this.gameId)) {
            await this.fetchGame();
        }

        if (this.item) {
            this.editedItem = this.getEditedItem(this.item);
        }

        if (this.hasCards) { await this.fetchCardTypes(); }
        // console.log(this.item);
        /* console.log('this.$route.params')
        console.log(this.$route.params) */

        this.fetchGameTypes();
    },
    /* watch: {
        dialog(val) {
            if (val) {
                this.editedItem = this.getEditedItem()
            }
        }
    }, */
    mounted() {
    },
    methods: {
        getEditedItem(itemInit = null) {
            const item = itemInit ?? null;

            var empty = !item

            return {
                id: empty ? '' : item.id,
                game_type_id: {
                    value: empty ? '' : +item.game_type_id,
                    error: ''
                },
                teams_count: {
                    value: empty ? '' : +item.teams_count,
                    error: ''
                },
                active: {
                    value: empty ? '' : item.active,
                    error: ''
                },
                cardTypes: {
                    value: (empty || !item.card_types) ? [] : item.card_types.map(type => type.id),
                    error: ''
                },
                name: {
                    value: empty ? '' : item.name ?? '',
                    error: ''
                },
                description: {
                    value: empty ? '' : item.description ?? '',
                    error: ''
                }
            }
        },

        initialize() {

        },
        async save() {
            const formData = new FormData();
            formData.append("game_type_id", this.editedItem.game_type_id.value);
            formData.append("teams_count", this.editedItem.teams_count.value ?? '');
            formData.append("active", this.editedItem.active.value ?? '');
            formData.append("name", this.editedItem.name.value ?? '');
            formData.append("description", this.editedItem.description.value ?? '');

            let urlAppend = ''
            let method = 'POST'
            if (this.editedItem.id) {
                urlAppend = '/' + this.editedItem.id
                method = 'PATCH'

                formData.append("_method", method);
            }

            /* console.log('********** formData ********')
            console.log(formData) */

            try {
                const result = await axios
                    .post(this.urlMain + urlAppend, formData, {
                        headers: { "Content-Type": "multipart/form-data" }
                    });

                /* const result = await axios({
                    method: method,
                    url: this.urlMain + urlAppend,
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" }

                }) */

                /* console.log('********** result ********')
                console.log(result) */

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.afterCreate(result.data.game)

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK'
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG))
                }
            } catch (error) {
                /* console.log('********** errors ********')
                console.log(error) */

                if (error?.response?.status === 422) {
                    if (error.response?.data?.errors?.length) {
                        error.response.data.errors.forEach((e) => {
                            if (e.source.pointer === '/data/attributes/title') {
                                this.editedItem.title.error = e.detail
                            }
                            if (e.source.pointer === '/data/attributes/body') {
                                this.editedItem.body.error = e.detail
                            }
                        })
                    }
                }

                this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
            }
        },
        afterCreate(item) {
            // this.isOnlyView = true;

            this.editedItem = this.getEditedItem(item);
            this.item = item;

            if (this.$route.params.id === 'new') {
                this.$router.replace({ params: { id: item.id } })
            }

            if (this.hasCards) { this.fetchCardTypes(); }
        },
        async fetchGameTypes() {
            try {
                const urlTypes = 'admin/games/types';

                const gameTypes = await axios
                    .get(urlTypes)

                this.gameTypes = gameTypes.data.gameTypes;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        async fetchGame() {
            try {
                const game = await axios
                    .get(`${this.urlMain}/${this.gameId}`)

                this.item = game.data.game;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        async fetchCardTypes() {
            try {
                const urlTypes = 'admin/games/cards/types';

                const types = await axios
                    .get(urlTypes)

                this.cardTypes = types.data.cardTypes;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        async changeCardTypesSelected() {
            this.editedItem.cardTypes.error = '';

            const formData = new FormData();
            formData.append("cards_types", this.editedItem.cardTypes.value);

            const gameId = '/' + this.editedItem.id

            try {
                const result = await axios
                    .post('admin/games/cards/types/game_card_types' + gameId, formData, {
                        headers: { "Content-Type": "multipart/form-data" }
                    });

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                }
            } catch (error) {
                this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
            }
        },
        infoItem(item) {
            this.$router.push({ name: 'GameInfo', params: { item, id: item.id + '' } })
        }
    }
}
</script>

<style >

</style>
